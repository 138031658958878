







import { Component, Vue } from "vue-property-decorator";
import EvaLinker from "@/components/EvaLinker.vue";

@Component({
	components: { EvaLinker },
})
export default class Home extends Vue {}
