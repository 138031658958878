




import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class NavItem extends Vue {
	@Prop(String) readonly path: string | undefined;
	@Prop(String) readonly title: string | undefined;
}
