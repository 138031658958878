
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class EvaLinker extends Vue {
	course = {
		lsf: "",
		pw: "",
	};
	openQuest(): void {
		window.location.href = `https://qualis.uni-saarland.de/eva/?l=${this.course.lsf.trim()}&p=${this.course.pw.trim()}`;
	}
}
