




















import { Component, Vue } from "vue-property-decorator";
import NavItem from "@/components/NavItem.vue";

@Component({
	components: { NavItem },
})
export default class NavMenu extends Vue {
	mobileHidden = true;
}
